















































import { PopUpProps, PriceListProduct } from "@/helpers/types";
import { Component, Vue, Watch} from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";
import { debounce } from 'lodash'

const pricelist = namespace("pricelist");
const popup = namespace("popup");

@Component
export default class PriceListTable extends Vue {

  private selected: Array<PriceListProduct> = [];
  private search = '';

  @pricelist.State
  public isLoading!: boolean;
  @pricelist.State
  public products!: Array<PriceListProduct>;
  private headers: Array<DataTableHeader> = [
    {
      text: "Part Number",
      value: "partNumber",            
      width: "30%"
    },
    {
      text: "Description",
      value: "description",           
      width: "60%",
    },
    {
      text: "List Price",
      value: "priceList",
      width: "10%"
    },
    {
      text: "Grade C(Fabric)",
      value: "gradeC_FabricUpcharge",
    },
    {
      text: "Grade D(Fabric)",
      value: "gradeD_FabricUpcharge",
    },
    {
      text: "Tinted Glass",
      value: "tintedGlass_ListUpcharge",
    },
    {
      text: "Frosted Glass",
      value: "frostedGlass_ListUpcharge",
    },
    {
      text: "Aqui Glass",
      value: "aquiGlass_ListUpcharge",
    },
    {
      text: "Grade C(Paint)",
      value: "gradeC_PaintUpcharge",
    }
  ];

  private debounceSearch: any;

  created(){ 
    this.debounceSearch = debounce(this.OnSearch, 1000);
  }

  @Watch('search', {deep: true})
  public onSearchChange(){
    this.debounceSearch();
  }

  public OnAddItems(): void {
    if (this.CanAddItems) {
      this.AddProducts(this.selected);
      this.OpenDialog({
        msg: 'Would you like to continue searching for products?',
        title: 'Add to Cart',
        icon: 'mdi-plus',
        onYes: this.AddProduct,
        onNo: this.NotAddProduct
      })
    }
  }

  public OnSearch(): void {
    this.GetProductsBySearch(this.search)
  }

  

  public ExportProducts() {
    if (this.selected.length <= 0 ){
      console.log('NOT EXPORT');
      return
    }
    if (this.selected.length === this.products.length){
      console.log('EXPORT ALL');
    } else {
      console.log('EXPORT SELECTED');
    }
  }

  public AddProduct(): void {    
    this.CloseDialog();
    this.ClearAllFilterField();
    this.selected = [];
  }

  public NotAddProduct(): void {
    this.ClearAllFilterField();
    this.CloseDialog();
    this.$router.push({ path: '/list-summary' });
  }

  get CanAddItems(): boolean {
    return !(this.selected.length <= 0 || this.selected.length > 10)
  }

  @pricelist.Action
  public AddProducts!: (productsSelected: Array<PriceListProduct>) => void;

  @pricelist.Action
  public ClearAllFilterField!: () => void;
  @pricelist.Action
  public GetProductsBySearch!: (search: string) => void;


  @pricelist.Action
  public ExportFileByParemeters!: () => void;

  @popup.Action
  public OpenDialog!: (data: PopUpProps) => void;

  @popup.Action
  public CloseDialog!: () => void;
}
