import { API } from "@/helpers/api";

export interface User {
    username: string;
}


async function login(username: string, password: string): Promise<User| undefined > {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    const res = await fetch(API.BASE_URL + "/auth", requestOptions)

    const data = await res.json();
    
    if (!res.ok)
        throw data;

    if (data) {
        localStorage.setItem('user', JSON.stringify(data));

        return data;
    }

}



function logout(): void {
    localStorage.removeItem('user');
}

export const userService  = {
    login,
    logout,
};