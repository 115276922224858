















































import { PriceListCategory } from "@/helpers/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

@Component
export default class CategoryDescription extends Vue {
  @Prop() readonly id!: string;
  @Prop() readonly title!: string;
  @Prop() readonly description!: string;
  @Prop() readonly imgs!: string[];

  @Prop({default: true}) readonly type!: boolean;

  public imgSelected = "";
  created() {
    this.imgSelected = this.imgs[0];
  }

  public selectImage(src: string) {
    this.imgSelected = src;
  }
}
