










import { LoginStatus, PriceListCategory, UserCredentials } from "@/helpers/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import NavBar from '@/components/NavBar.vue';
import PopUp from '@/components/PopUp.vue';
import { namespace } from "vuex-class";
@Component({
  components: {
    NavBar,
    PopUp
  }
})
export default class App extends Vue {
   
}
