import { Product, TreeItem } from '@/helpers/types';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { Service } from '@/services'
import { ProductNavigator } from '@/helpers/product-navigation';

@Module({ namespaced: true })
class ConfiguratorModule extends VuexModule {
    public isLoading = false;
    public options?: TreeItem[] = undefined;
    public currentOptions?: TreeItem[] = undefined;
    public configured = false;

    @Mutation
    public loadProductOptions(options: TreeItem[]): void {
        this.isLoading = false;
        this.options = options;
    }

    @Mutation
    public loadRequest(): void {
        this.isLoading = true;
    }

    @Mutation
    public loadFail(): void {
        this.isLoading = false;
    }

    @Mutation
    public loadCurrentOptions(options: TreeItem[]): void {
        this.currentOptions = options;
    }

    @Mutation
    public setOptionActive(option: TreeItem): void {
        this.options?.forEach(x => {
            if (x.model.id === option.model.id) {
                x.active = true
            }
        });
    }

    @Mutation
    public SetConfigured(): void {
        this.configured = true;
    }

    @Mutation 
    public ResetValues(): void {
        this.isLoading = false;
        this.options = undefined;
        this.currentOptions = undefined;
        this.configured = false;
    }

    @Action
    public GetProductOptions(id: number): void {
        this.context.commit('loadRequest');
        Service.GetProductOption(id)
            .then(
                options => {
                    //first option is the product itself.
                    this.context.commit('loadProductOptions', options);
                    if (options.length > 1) { //has options to select
                        this.context.commit('loadCurrentOptions',
                        ProductNavigator.GetItemsNextLevel(options, { level: 1, id: options[0].model.id }));
                    } else {
                        this.context.commit('SetConfigured');
                    }
                },
                error => {
                    this.context.commit('loadFail');
                    // this.context.dispatch('alert/ErrorAlert', error);
                }
            );
    }

    @Action
    public NavigateOption(option: TreeItem): void {
        this.context.commit('setOptionActive', option);
        const item = option.model;
        const nextLevel = item.level + 1;
        let itemsNextLevel = null;
        if (item.bomType === 1) {
            itemsNextLevel = ProductNavigator.GetItemsNextLevel(this.options, { level: nextLevel, id: item.id });
            console.log(itemsNextLevel);
            if (itemsNextLevel !== null) {
                this.context.commit('loadCurrentOptions', itemsNextLevel);
            }
        } else {
            if (item.bomType === 2) {
                itemsNextLevel = ProductNavigator.GetItemsNextLevel(this.options, { level: nextLevel, id: item.id });
                if (itemsNextLevel !== null) {
                    //commit('needToReturnAfter', { level: nextLevel, id: item.id });
                    this.context.commit('loadCurrentOptions', itemsNextLevel);
                }
            }
            else {
                if (item.bomType === 0) {
                    //var levelToReturn = getters.getLevelToReturn;
                    const levelToReturn = undefined;
                    //this.context.commit('popLevelReturn');
                    itemsNextLevel = ProductNavigator.GetItemsNextLevel(this.options, levelToReturn);
                    if (itemsNextLevel.filter(x => x.model.id === item.id).length > 0) {
                        //levelToReturn = getters.getLevelToReturn;
                        itemsNextLevel = ProductNavigator.GetItemsNextLevel(this.options, levelToReturn);
                    }
                    while ((itemsNextLevel) && (itemsNextLevel.length > 0) 
                            && (itemsNextLevel.length === 1 || ProductNavigator.AllOptionsSelected(itemsNextLevel))) {
                        //this.context.commit('popLevelReturn');
                        //levelToReturn = getters.getLevelToReturn;
                        itemsNextLevel = ProductNavigator.GetItemsNextLevel(this.options, levelToReturn);
                    }
                    if (ProductNavigator.AllIsSelected(itemsNextLevel)) {
                        this.context.commit('SetConfigured');
                    } else {
                        this.context.commit('refreshCurrentOptions', itemsNextLevel);
                    }
                }
            }
        }
    }

    @Action
    public ConfiguratorReset(): void {
        this.context.commit('ResetValues');
    }
    
    get GetOptionsHTML(): string {
        if (!this.options) return '';
        const product = this.context.rootState.products.currentProduct;
        const node = {
            name: product.name,
            model: product.model,
            cover: '',
            active: true,
            isConfigurated: false
        }
        return ProductNavigator.LoadTreeHTML(node, this.GetCurrentTreeActiveItems || [], true);
    }

    get GetCurrentTreeActiveItems(): TreeItem[] | undefined{
        return this.options?.filter(x => x.active)
        
    }
    get GetCurrentPrice(): number {
        if (!this.options) return 0;
        const product = this.context.rootState.products.currentProduct;
        const catalogPrice = product.model.catalogPrice ?? 0;
        const prices = this.options.filter(x => x.active)
                                .map(x => x.model.price);
        prices.push(catalogPrice);
        if (prices.length < 1) return 0;
        return prices.reduce((acc, price) => acc + price);
    }

}

export default ConfiguratorModule;