import { API } from '@/helpers/api';
import { downloadBlob } from '@/helpers/donwload-blob';
import { PriceListCategory, PriceListProduct, ScreenParameters } from "@/helpers/types";


async function GetConfiguratorData(data: ScreenParameters): Promise<PriceListCategory[]>{
   const requestOptions = {
       method: 'POST',
       headers: { 'Content-Type' : 'application/json' },
       body: JSON.stringify(data)
   }
   const res = await fetch(API.BASE_URL + '/pricelist/categories', requestOptions);
   const options = await res.json();
   
    return options;
}

async function GetProducts(data: ScreenParameters): Promise<PriceListProduct[]>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type' : 'application/json' },
        body: JSON.stringify(data)
    }
    const res = await fetch(API.BASE_URL + '/pricelist/products', requestOptions);
    const options = await res.json();
    
     return options;
 }

 async function GetProductsBySearch(data: ScreenParameters, search: string): Promise<PriceListProduct[]>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type' : 'application/json' },
        body: JSON.stringify({parameters: data, search})
    }
    const res = await fetch(API.BASE_URL + '/pricelist/products-search', requestOptions);
    const options = await res.json();
    
     return options;
 }

 async function GetParameters(item: PriceListProduct): Promise<ScreenParameters>{
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type' : 'application/json' }
    }
    const res = await fetch(API.BASE_URL + '/pricelist/parameters?product=' + item.partNumber, requestOptions);
    const options = await res.json();
    
     return options;
 }

 async function ExportProducts(data: PriceListProduct[]): Promise<void> {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type' : 'application/json' },
        body: JSON.stringify(data)
    }
    const res = await fetch(API.BASE_URL + '/pricelist/export', requestOptions);
    const file = await res.blob();

    downloadBlob(file, `ScreenConfigurator-Export_${new Date().toISOString().slice(0,10).replace(/-/g,"")}.csv`);
 }

 async function ExportProductsToSIF(data: PriceListProduct[]): Promise<void> {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type' : 'application/json' },
        body: JSON.stringify(data)
    }
    const res = await fetch(API.BASE_URL + '/pricelist/export-sif', requestOptions);
    const file = await res.blob();

    downloadBlob(file, `ScreenConfigurator-Export_${new Date().toISOString().slice(0,10).replace(/-/g,"")}.sif`);
 }

 async function ExportProductsByParemeters(data: ScreenParameters): Promise<void> {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type' : 'application/json' },
        body: JSON.stringify(data)
    }
    const res = await fetch(API.BASE_URL + '/pricelist/export-paremeters', requestOptions);
    const file = await res.blob();

    downloadBlob(file, `ScreenConfigurator-Export_${new Date().toISOString().slice(0,10).replace(/-/g,"")}.csv`);
 }


export const PriceListService = {
    GetConfiguratorData,
    GetProducts,
    GetProductsBySearch,
    GetParameters,
    ExportProducts,
    ExportProductsByParemeters,
    ExportProductsToSIF
}