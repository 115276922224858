import { ScreenParametersInitial } from '@/helpers/defaults';
import { PopUpProps, PriceListCategory, PriceListProduct, ScreenParameters } from '@/helpers/types';
import { PriceListService } from '@/services/price-list';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';


@Module({ namespaced: true })
class PriceListModule extends VuexModule {
    public categoriesOptions: Array<PriceListCategory> = [];
    public isLoading = false;
    public parameters: ScreenParameters = ScreenParametersInitial();
    public products: Array<PriceListProduct> = [];

    public selectedProducts: Array<PriceListProduct> = [];

    @Mutation
    public loadRequest(): void {
        this.isLoading = true;
    }

    @Mutation
    public loadFail(): void {
        this.isLoading = false;
        this.categoriesOptions = [];
    }

    @Mutation
    public loadcateogiresOption(options: Array<PriceListCategory>): void {
        this.categoriesOptions = options;
        this.isLoading= false;
    }
    @Mutation
    public loadProducts(products: Array<PriceListProduct>): void {
        this.products = products;
        this.isLoading= false;
    }

    @Mutation
    public addCartProducts(productsSelected: Array<PriceListProduct>): void {
        this.selectedProducts = this.selectedProducts.concat(productsSelected).filter((product, index, self) =>
        index === self.findIndex((t) => (
          t.partNumber === product.partNumber
        ))
      )
        this.isLoading= false;
    }

    @Mutation
    public RemoveCartProduct(item: PriceListProduct): void {
        
        const index = this.selectedProducts.findIndex(x => x.partNumber === item.partNumber);
        if (index !== -1){            
            this.selectedProducts.splice(index, 1);
        }
        this.isLoading= false;
    }

    @Mutation
    public RemoveCartProducts(): void {
        this.selectedProducts = [];
        this.isLoading= false;
    }

    @Mutation
    public setParameters(paremeters: ScreenParameters): void {
        this.parameters = paremeters;
        this.isLoading = false;
    }

    @Mutation
    public setOptionClicked({ option, multiselect }: any): void {
        const parameter = this.parameters.values.find(x => x.field === option.field);
        if (parameter){            
            parameter.values.push(option.label);
            parameter.multiselect = multiselect;
        }
    }

    @Mutation
    public removeFilterField({ field, value }: { field: string; value: string }): void {

        const parameter = this.parameters.values.find(x => x.field === field);
        if (parameter){    
            parameter.values = (value === null) ? [] : parameter.values.filter(v => v !== value);
            parameter.multiselect = parameter.values.length > 0;
        }
    }

    @Action
    public GetCategoriesOption(): void {
        this.context.commit('loadRequest');
        PriceListService.GetConfiguratorData(this.parameters)
            .then(
                categories =>  this.context.commit('loadcateogiresOption', categories),
                error => {
                    this.context.commit('loadFail');
                    // this.context.dispatch('alert/ErrorAlert', error);
                }
            );
    }  
    
    @Action
    public ClickCategoryOption( { clicked, multiselect }: any): void {
        this.context.commit('setOptionClicked', {option: clicked, multiselect: multiselect });
        this.context.dispatch('GetCategoriesOption');
        this.context.dispatch('GetProducts');
    }

    @Action
    public ClearFilterField({ field, value }: { field: string; value: string }): void {
        this.context.commit('removeFilterField', { field, value });
        this.context.dispatch('GetCategoriesOption');
        this.context.dispatch('GetProducts');
    }

    @Action
    public ClearAllFilterField(): void {        
        this.context.commit('setParameters', ScreenParametersInitial());
        this.context.dispatch('GetCategoriesOption');
        this.context.dispatch('GetProducts');
    }

    @Action
    public AddProducts(productsSelected: Array<PriceListProduct>): void {
        this.context.commit('addCartProducts', productsSelected);
    }

    @Action
    public AddCurrentProductsToCart(): void {
        this.context.commit('addCartProducts', this.products);
    }

    @Action
    public GetProducts(): void {
        this.context.commit('loadRequest');
        PriceListService.GetProducts(this.parameters)
            .then(
                products =>  this.context.commit('loadProducts', products),
                error => {
                    this.context.commit('loadFail');
                    // this.context.dispatch('alert/ErrorAlert', error);
                }
            );
    }

    @Action
    public GetProductsBySearch(search: string): void {
        this.context.commit('loadRequest');
        PriceListService.GetProductsBySearch(this.parameters, search)
            .then(
                products =>  this.context.commit('loadProducts', products),
                error => {
                    this.context.commit('loadFail');
                    // this.context.dispatch('alert/ErrorAlert', error);
                }
            );
    }

    @Action
    public GoToConfigurationForProduct(item: PriceListProduct): void {
        this.context.commit('loadRequest');
        PriceListService.GetParameters(item)
            .then (
                param => {
                    this.context.commit('setParameters', param);    
                    this.context.dispatch('GetCategoriesOption');
                    this.context.dispatch('GetProducts');
                },
                error => {
                    this.context.commit('loadFail');
                    // this.context.dispatch('alert/ErrorAlert', error);
                }
            )
    }

    @Action
    public ExportFile(): void {
        PriceListService.ExportProducts(this.selectedProducts);
    }
    @Action
    public ExportSIFFile(): void {
        PriceListService.ExportProductsToSIF(this.selectedProducts);
    }

    @Action
    public ExportFileByParemeters(): void {
        PriceListService.ExportProductsByParemeters(this.parameters);
    }

    @Action
    public ClearAllSelectedProducts(): void {
        this.context.commit('RemoveCartProducts');
    }

    @Action
    public RemoveProduct(item: PriceListProduct): void {
        this.context.commit('loadRequest');
        this.context.commit('RemoveCartProduct', item);
    }
    
    get GetCategoryByField() {
        return (field: string) => {
            return this.categoriesOptions.filter(x => x.field == field);
        }
    }

    get GetProductsInCart(): string {
       return this.selectedProducts?.length.toString();
    }

    get CheckCategoryIsSelected() {
        return (field: string) => {
            return this.parameters.values.find(x => x.field === field)?.values.length ?? 0 > 0;
        }
    }

}

export default PriceListModule;