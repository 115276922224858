



























































































import { PopUpProps, PriceListProduct } from "@/helpers/types";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { namespace } from "vuex-class";
const pricelist = namespace("pricelist");
const popup = namespace("popup");

@Component({
  components: {},
})
export default class PriceListSummary extends Vue {

  @pricelist.State
  public isLoading!: boolean;
  @pricelist.State
  public selectedProducts!: Array<PriceListProduct>;

  private headers: Array<DataTableHeader> = [
    {
      text: "Part Number",
      value: "partNumber",
      width: "25%",
    },
    {
      text: "Qty.",
      value: "qty",
      width: "10%"
    },
    {
      text: "Description",
      value: "description",
      width: "70%",
    },
    {
      text: "List Price",
      value: "priceList",
      width: "10%",
    },
    {
      text: "Grade C(Fabric)",
      value: "gradeC_FabricUpcharge",
    },
    {
      text: "Grade D(Fabric)",
      value: "gradeD_FabricUpcharge",
    },
    {
      text: "Tinted Glass",
      value: "tintedGlass_ListUpcharge",
    },
    {
      text: "Frosted Glass",
      value: "frostedGlass_ListUpcharge",
    },
    {
      text: "Aqui Glass",
      value: "aquiGlass_ListUpcharge",
    },
    {
      text: "Grade C(Paint)",
      value: "gradeC_PaintUpcharge",
    },
     {
      text: "Total",
      value: "total",
    },
    {
      text:"Actions",
      value: "actions"
    }
  ];

  public RemoveItem(item: PriceListProduct) {
    this.RemoveProduct(item);
  }

  public OpenConfiguration(item: PriceListProduct){
    this.GoToConfigurationForProduct(item);
    this.$router.push({ path: '/price-list' });
  }

  public ClearSelectedProducts() {
    this.OpenDialog({
        msg: 'This action will remove all the products selected. Do you want to continue?',
        title: 'Warning!',
        icon: 'mdi-alert-circle-outline',
        onYes: this.ClearItems,
        onNo: this.CloseDialog
      })
  }

  public ClearItems() {
    this.CloseDialog();
    this.ClearAllSelectedProducts();
  }

  @pricelist.Action
  public GoToConfigurationForProduct!: (item: PriceListProduct) => void;

  @pricelist.Action
  public ExportFile!: () => void;
  
  @pricelist.Action
  public ExportSIFFile!: () => void;

  @pricelist.Action
  public ClearAllSelectedProducts!: () => void;
  
  @pricelist.Action
  public RemoveProduct!: (item: PriceListProduct) => void;

  @popup.Action
  public OpenDialog!: (data: PopUpProps) => void;

  @popup.Action
  public CloseDialog!: () => void;
}
