
































import { Component, Vue } from 'vue-property-decorator';
import ConfigurationCategory from '@/components/PriceList/ConfigurationCategory.vue'
import { namespace } from 'vuex-class'
import { DescriptionService } from '@/services/descriptions.service';
const pricelist = namespace('pricelist');

@Component({
    components: {
        ConfigurationCategory
    }
})
export default class Configuration extends Vue {
    private ShowDescription = false;
    
    private screenData = DescriptionService.screen;
    private materialData = DescriptionService.material;
    private mountData = DescriptionService.mount;
    private whereusedData = DescriptionService.whereused;
    private sizedforData = DescriptionService.sizedfor;
    private handednessData = DescriptionService.handedness;
    private withorwithoutcutoutData = DescriptionService.withourwithoutcutout;

    private layout = false;

    get SwitchLabel(): string {
        return (this.layout) ? 'Hide Details' : 'Show Details'
    }

    
    @pricelist.Action
    public ClearAllFilterField!: () => void;
}
