














import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class'
import Configuration from '@/components/PriceList/Configuration.vue'
import PriceListTable from '@/components/PriceList/PriceListTable.vue'
import { ScreenParameters } from '@/helpers/types';
import { ScreenParametersInitial } from '@/helpers/defaults';
const pricelist = namespace('pricelist');

@Component({
    components: {
        Configuration,
        PriceListTable
    }
})
export default class PriceList extends Vue {

    created(){
        this.GetCategoriesOption(ScreenParametersInitial());
        this.GetProducts(ScreenParametersInitial());
    }

    private cols = 6;

    public ChangeLayout(layout: boolean): void{
        this.cols = (layout) ? 12: 6;
    }

    @pricelist.Action
    public GetCategoriesOption!: (data: ScreenParameters) => void;
    @pricelist.Action
    public GetProducts!: (data: ScreenParameters) => void;
}
