import { TreeItem, NavigationInfo } from '@/helpers/types'


function GetItemsNextLevel(current: TreeItem[] | undefined, info: NavigationInfo | undefined): TreeItem[]{
    if (!info) return [];
    if (!current) return [];
    return current.filter((x: TreeItem) => x.model.level === info.level && x.model.parent_id === info.id);
}

function AllOptionsSelected(list: TreeItem[]): boolean{
    return (list.filter(x => x.active === false).length === 0)
        && (list[0].model.level !== 1)
}

function AllIsSelected(list: TreeItem[]): boolean{
    if ((!list) || (list.length === 0)) return true;
    return (list.filter(x => x.active === false).length === 0)
    && (list[0].model.level === 1)
}


function LoadTreeHTML(item: TreeItem, items: TreeItem[], first: boolean): string {
    if (item.model.hasChild === false) {
        return `<li>${item.name}</li>`;
    } else {
        let count = 0;
        let html =`<ul class='${(first) ? 'product-tree' : ''}'>`;
        for(let i = 0; i < items.length; i++){
            const id = first ? `P${item.model.id}` : item.model.id;
            if (items[i].model.parent_id === id){
                count++;
                html += LoadTreeHTML(items[i], items.slice(count), false);
            }
        }
        html += '</ul>'
        const parentCls = (first) ? 'parent' : ''
        const name = (first) ? `<b>${item.model.item}: ${item.name}</b>` : item.name;
        return `<li class='has-child ${parentCls}'>${name}${html}</li>`; 
    }
}


export const ProductNavigator = {
    GetItemsNextLevel,
    AllIsSelected,
    AllOptionsSelected,
    LoadTreeHTML
}