import { Category, Product } from '@/helpers/types';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { Service } from '@/services'

@Module({ namespaced: true })
class ProductsModule extends VuexModule{
    public products?: Array<Product> = undefined;
    public categories?: Array<Category> = undefined;
    public currentCategory?: Category = undefined;
    public currentProduct?: Product = undefined;
    public isLoading = false;

    @Mutation
    public loadCategories(categories: Array<Category>): void{
        this.isLoading = false;
        this.categories = categories;
    }
    @Mutation
    public loadProducts(products: Array<Product>): void{
        this.isLoading = false;
        this.products = products;
    }
    @Mutation
    public loadProduct(product: Product): void{
        this.isLoading = false;
        this.currentProduct = product;
    }

    @Mutation
    public loadCurentCategory(category: Category): void{
        this.isLoading = false;
        this.currentCategory = category;
    }

    @Mutation
    public loadRequest(): void{
        this.isLoading = true;
    }

    @Mutation
    public loadFail(): void{
        this.isLoading = false;
    }

    @Action
    public GetCategories(): void {
        this.context.commit('loadRequest');
        Service.GetCategories()
            .then(
                categories =>  this.context.commit('loadCategories', categories),
                error => {
                    this.context.commit('loadFail');
                    // this.context.dispatch('alert/ErrorAlert', error);
                }
            );
    }

    @Action
    public GetCategoryBySlug(slug: string): void {
        this.context.commit('loadRequest');
        Service.GetCategories()
            .then(
                categories =>  {
                    this.context.commit('loadCategories', categories)
                    this.context.commit('loadCurentCategory', categories.filter(x => x.slug == slug)[0])
                },
                error => {
                    this.context.commit('loadFail');
                    // this.context.dispatch('alert/ErrorAlert', error);
                }
            );
    }

    @Action
    public GetProductsByCategory(category: Category): void {
        this.context.commit('loadRequest');
        Service.GetProductsByCategory(category)
            .then(
                products =>  this.context.commit('loadProducts', products),
                error => {
                    this.context.commit('loadFail');
                    // this.context.dispatch('alert/ErrorAlert', error);
                }
            );
    }

    @Action
    public GetProductByID(id: number): void {
        this.context.commit('loadRequest');
        Service.GetProductByID(id)
            .then(
                product =>  this.context.commit('loadProduct', product),
                error => {
                    this.context.commit('loadFail');
                    // this.context.dispatch('alert/ErrorAlert', error);
                }
            );
    }
  
    get GetProductImg(): string {
        if (!this.currentProduct) return '';
        return this.currentProduct.cover;
    }
}

export default ProductsModule;