

























































































import { CartLine } from "@/helpers/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const cart = namespace("cart");
const pricelist = namespace("pricelist");

@Component
export default class CartMenu extends Vue {
  @pricelist.Getter("GetProductsInCart") readonly numberOfProducts!: string;

  @cart.State
  public items!: CartLine[];
  private showMenu = false;
  private x = 0;
  private y = 0;

  @Prop({ default: true }) showCart!: boolean;

  public Submit(): void {
    this.$router.push("/checkout");
  }

  public RemoveItemCart(index: number, item: CartLine): void {
    this.OnRemoveItem(index, item);
  }

   public OpenCartMenu(event: MouseEvent): void {
      event.preventDefault();
      this.$router.push("/list-summary"); // FIX HERE
      return;
      // if (this.items.length < 1) return;
      // this.showMenu = false
      // this.x = event.clientX;
      // this.y = event.clientY;
      // this.$nextTick(() => {
      //   this.showMenu = true
      // })
    }

  @cart.Action
  public OnRemoveItem!: (index: number, item: CartLine) => void;
}
