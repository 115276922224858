import { PopUpProps } from '@/helpers/types';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';


@Module({ namespaced: true })
class PopUpModule extends VuexModule{
    public dataPopup: PopUpProps = {
        msg: '',
        title: '',
        icon: 'mdi-plus'
    }
    public show = false;

    @Mutation
    public ShowPopup(data: PopUpProps){
        this.dataPopup = {
            msg: data.msg,
            title: data.title,
            icon: data.icon,
            onNo: data.onNo,
            onYes: data.onYes
        };
        console.log(this.dataPopup);
        this.show = true;
    }

    @Mutation
    public HidePopup(){
        this.dataPopup = { msg: '', title: '', icon: 'mdi-plus'};
        this.show = false;
    }

    @Action
    public OpenDialog(data: PopUpProps){
        this.context.commit('ShowPopup', data);
    }

    @Action
    public CloseDialog(){
        this.context.commit('HidePopup');
    }
}

export default PopUpModule;