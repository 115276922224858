export const ScreenParametersInitial = () => ({
    values: 
    [
        {
            field: "Screen_Type",
            values: [],
            multiselect: false,
          },
          {
            field: "Where_Used",
            values: [],
            multiselect: false,
          },
          {
            field: "Material",
            values: [],
            multiselect: false,
          },
          {
            field: "Sized_For",
            values: [],
            multiselect: false,
          },
          {
            field: "Mount_Type",
            values: [],
            multiselect: false,
          },
          {
            field: "With_Or_Without_Cutout",
            values: [],
            multiselect: false,
          },
          {
            field: "Handedness",
            values: [],
            multiselect: false,
          },
          {
            field: "Heights",
            values: [],
            multiselect: false,
          },
          {
            field: "Depth_Width",
            values: [],
            multiselect: false,
          },
          
    ]
});