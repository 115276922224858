import { userService, User } from '@/services/user'
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { LoginStatus, UserCredentials } from '@/helpers/types';
import router from '@/router';


const localUser = JSON.parse(localStorage.getItem('user') || '{}');


@Module({ namespaced: true })
class UserModule extends VuexModule {
    public user: User | null = Object.keys(localUser).length > 0 ? localUser : null
    public status: LoginStatus = {
        loggingIn: false,
        loggedIn: Object.keys(localUser).length > 0 ? true : false
    }
    @Mutation
    public loginRequest(): void {
        this.status.loggingIn = true;
    }

    @Mutation
    public loginSuccess(user: User): void {
        this.status.loggingIn = false;
        this.status.loggedIn = true;
        this.user = user;
    }

    @Mutation
    public loginFailure(): void {
        this.status.loggingIn = false;
        this.status.loggedIn = false;
        this.user = null;
    }

    @Mutation
    public logoutUser(): void {
        this.status.loggingIn = false;
        this.status.loggedIn = false;
        this.user = null;
    }

    @Action({ rawError: true })
    public login(credentials: UserCredentials): void {
        this.context.commit('loginRequest');
        userService.login(credentials.username, credentials.password)
            .then(
                user => {
                    this.context.commit('loginSuccess', user);
                    router.push('/price-list');
                },
                error => {
                    this.context.commit('loginFailure');
                    this.context.dispatch('alert/ErrorAlert', error, { root: true });
                }
            )
    }

    @Action
    public logout(): void {
        userService.logout();
        this.context.commit('logoutUser');
        router.push('/login');
    }
}


export default UserModule;  