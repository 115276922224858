







































import { CartLine, LoginStatus } from '@/helpers/types';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import CartMenu from '@/components/CartMenu.vue'
const cart = namespace('cart');
const user = namespace('user');

@Component({
  components: {
    CartMenu
  }
})
export default class NavBar extends Vue {
    private logged = false;
    private showCart = true;
    @user.State
    public status!: LoginStatus
    
    public GoHome(): void {
      this.$router.push('/');
    }

    get showHome(): boolean {
      return ((this.$route.name !== 'login') ) //&& this.logged
    }

    @user.Action
    public logout!: () => void;
}
