



























































import { PriceListCategory, UserCredentials } from "@/helpers/types";
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
const user = namespace('user')
@Component
export default class Login extends Vue {
    private ShowPw = false;
    private valid = false;
    private username = '';
    private usernameRules: Array<any> = [
        ( v: string ): string | boolean => (!!v || 'Username is required'),
    ];
    private password = '';
    private passwordRules: Array<any> = [
        ( v: string ): string | boolean => (!!v || 'Password is required'),
    ];
    public submit(): void {
        const isValid = (this.$refs.form as Vue & { validate: () => boolean}).validate();
        if (isValid){
        this.login({
            username: this.username,
            password: this.password
        })
        }
    }

    @user.Action
    public login!: (credentials: UserCredentials) => void


}
