










































































import { PriceListCategory } from '@/helpers/types';
import CategoryDescription from '@/components/PriceList/CategoryDescription.vue'
import PriceList from '@/views/PriceList.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ScrollHelper } from '@/helpers/scrollhelper';
import { namespace } from 'vuex-class'
const pricelist = namespace('pricelist')

@Component({
    components: {
        CategoryDescription
    }
})
export default class ConfigurationCategory extends Vue {
    @Prop() readonly category!: string;
    @Prop() readonly title!: string;
    @Prop({default: true}) readonly layout!: boolean;
    @Prop({default: true}) readonly descriptionType!: boolean;
    @Prop({default: () => []}) readonly descriptions!: any[];
    @pricelist.Getter("GetCategoryByField") GetCategoryByField!: (field: string) => Array<PriceListCategory>;

    private expand = true;
    private multiSelect = false;

    @pricelist.Getter("CheckCategoryIsSelected") HasFilter!: (field: string) => boolean;

    @pricelist.Action
    public ClickCategoryOption!: ({ clicked, multiselect }: any) => void;
     @pricelist.Action
    public ClearFilterField!: ({ field, value }: { field: string; value: string}) => void;

    public ClickOnCategory(clicked: PriceListCategory): void { 
        if (clicked.selected) {
            const data = {
                field: this.category,
                value: clicked.label
            }
            this.ClearFilterField(data);
        } else {      
            this.ClickCategoryOption({clicked, multiselect: this.multiSelect});
        }
    }

    public toggleMultiSelect(): void {
        this.multiSelect = !this.multiSelect;
    }
    public toggleExpand(): void {
        this.expand = !this.expand;
    }
    get ExpandLabel(): string {
        return (this.expand) ?  'Collapse' : 'Expand';
    }

    get ExpandIcon(): string {
        return (this.expand) ?  'mdi-unfold-less-horizontal' : 'mdi-unfold-more-horizontal';
    }

    get headerTitle(): string {
        if (this.expand) return '';

        const selected = this.GetCategoryByField(this.category).filter(x => x.selected)?.map(x => x.label).join(' | ');
        return ` - (${(selected ? selected : 'No option selected.')})`;
    }

    public calculateScrollTo(index: number): any {
        const options = '';
        // const options = {
        //     el: `#item-${(index + this.category)}`,
        //     container: `#desc_${this.category}`,
        //     duration: 500,
        //     lazy: true,
        //     easing: 'linear',
        //     force: true,
        //     cancelable: true,
        //     x: false,
        //     y: true
        // }

        return options;
    }
}
