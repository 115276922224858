import Vue from 'vue'
import Vuex from 'vuex'
import ConfiguratorModule from './configurator.module'
import ProductsModule from './products.module'
import CartModule from './cart.module'
import PopUpModule from './popup.module'
import PriceListModule from './pricelist.module'
import UserModule from './account.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    products: ProductsModule,
    configurator: ConfiguratorModule,
    cart: CartModule,
    popup: PopUpModule,
    pricelist: PriceListModule,
    user: UserModule
  }
})
