import { delay } from '@/helpers/delay';
import { Category, HomeCategory, Product, TreeItem } from '@/helpers/types';


const homedata = [
    {
        imgSrc: '/assets/images/banner_work_from_home.jpg',
        title: 'Work From Home.',
        description: '',
        direction: false,
    },
    {
        imgSrc: 'https://imagelibrary.ais-inc.com/files/images/_medium/Lobby-1-C__1_.jpg',
        title: 'We get you. <br/> We really get you.',
        description: 'Really. We get that you’re running a business, where every asset and deadline affects your profitability. That’s why we work so hard to make sure your needs are flawlessly achieved, by being fast, diligent, and ready when you need us.',
        direction: false
    },
    {
        imgSrc: 'https://imagelibrary.ais-inc.com/files/images/_medium/Lobby-1-C__1_.jpg',
        title: 'We get you. <br/> We really get you.',
        description: 'Really. We get that you’re running a business, where every asset and deadline affects your profitability. That’s why we work so hard to make sure your needs are flawlessly achieved, by being fast, diligent, and ready when you need us.',
        direction: true
    }
]
const categories = [
    {
        name: 'Tables and Accessories',
        slug: 'tables-and-accessories',
        cover: 'https://www.ais-inc.com/images/banners/Calibrate_Conference_Banner.jpg',
        subCategories: [
            {
                name: 'Accessories',
                slug: 'accessories'
            },
            {
                name: 'Height Adjustable Tables',
                slug: 'height-adjustable-tables'
            },
            {
                name: 'Multi-purpose Table',
                slug: 'multi-purpose-table'
            }
        ]
    },
    {
        name: 'Seating and Storage',
        slug: 'seating-and-storage',
        cover: 'https://portal99.ais-inc.com/assets/images/header/43619_10EGIRL.jpg',
        subCategories: []
    },
]

const products = 
[
    {
        "model": {
            "id": 43325,
            "catalog": "SCR",
            "item": "T-RCR246029HG",
            "description": "24\" X 60\" Height Adjustable Table",
            "height": 0.0,
            "width": 0.0,
            "depth": 0.0,
            "weight": 0.0,
            "name": "T-RCR246029HG - 24\" X 60\" Height Adjustable Table",
            "catalogPrice": 2325.0,
            "markCheckbox": 0,
            "categoryName": "Tables and Accessories",
            "details": [
                {
                    "detailDescription": "Installation Video",
                    "detailType": 0,
                    "url": "https://player.vimeo.com/video/407289824"
                },
                {
                    "detailDescription": "Installation Instructions",
                    "detailType": 1,
                    "url": "https://www.ais-inc.com/files/Tables_-_Day_to_Day_Tables_-_Steel_Base_Tables_T-SBTINSTALL.pdf "
                }
            ]
        },
        "name": "24\" X 60\" Height Adjustable Table",
        "cover": "https://portal99.ais-inc.com/assets/images/t-rcr246029hg.jpg",
        "active": false,
        "instruction": true
    },
    {
        "model": {
            "id": 43330,
            "catalog": "SCR",
            "item": "A-ERDMASB",
            "description": "Double Monitor Arm",
            "height": 0.0,
            "width": 0.0,
            "depth": 0.0,
            "weight": 0.0,
            "name": "A-ERDMASB - Double Monitor Arm",
            "catalogPrice": 1136.0,
            "markCheckbox": 0,
            "categoryName": "Tables and Accessories",
            "details": []
        },
        "name": "Double Monitor Arm",
        "cover": "https://portal99.ais-inc.com/assets/images/a-erdmasb.jpg",
        "active": false,
        "instruction": false
    },
    {
        "model": {
            "id": 43328,
            "catalog": "SCR",
            "item": "T-RCR244829HG",
            "description": "24\" X 48\" Height Adjustable Table",
            "height": 0.0,
            "width": 0.0,
            "depth": 0.0,
            "weight": 0.0,
            "name": "T-RCR244829HG - 24\" X 48\" Height Adjustable Table",
            "catalogPrice": 2267.0,
            "markCheckbox": 0,
            "categoryName": "Tables and Accessories",
            "details": [
                {
                    "detailDescription": "Installation Video",
                    "detailType": 0,
                    "url": "https://player.vimeo.com/video/407289824"
                },
                {
                    "detailDescription": "Installation Instructions",
                    "detailType": 1,
                    "url": "https://www.ais-inc.com/files/Tables_-_Day_to_Day_Tables_-_Steel_Base_Tables_T-SBTINSTALL.pdf "
                }
            ]
        },
        "name": "24\" X 48\" Height Adjustable Table",
        "cover": "https://portal99.ais-inc.com/assets/images/t-rcr244829hg.jpg",
        "active": false,
        "instruction": true
    },
    {
        "model": {
            "id": 43331,
            "catalog": "SCR",
            "item": "T-RCR306029HG",
            "description": "TBL, REC, 2mm, 30Dx60Wx29H, HAL, E SERIES GLD 2 STAGE MS",
            "height": 0.0,
            "width": 0.0,
            "depth": 0.0,
            "weight": 0.0,
            "name": "T-RCR306029HG - TBL, REC, 2mm, 30Dx60Wx29H, HAL, E SERIES GLD 2 STAGE MS",
            "catalogPrice": 2364.0,
            "markCheckbox": 0,
            "categoryName": "Tables and Accessories",
            "details": [
                {
                    "detailDescription": "Installation Video",
                    "detailType": 0,
                    "url": "https://player.vimeo.com/video/407289824"
                },
                {
                    "detailDescription": "Installation Instructions",
                    "detailType": 1,
                    "url": "https://www.ais-inc.com/files/Tables_-_Day_to_Day_Tables_-_Steel_Base_Tables_T-SBTINSTALL.pdf "
                }
            ]
        },
        "name": "TBL, REC, 2mm, 30Dx60Wx29H, HAL, E SERIES GLD 2 STAGE MS",
        "cover": "https://portal99.ais-inc.com/assets/images/t-rcr306029hg.jpg",
        "active": false,
        "instruction": true
    },
    {
        "model": {
            "id": 43332,
            "catalog": "SCR",
            "item": "A-ERSMASB",
            "description": "Single Monitor Arm",
            "height": 0.0,
            "width": 0.0,
            "depth": 0.0,
            "weight": 0.0,
            "name": "A-ERSMASB - Single Monitor Arm",
            "catalogPrice": 618.0,
            "markCheckbox": 0,
            "categoryName": "Tables and Accessories",
            "details": []
        },
        "name": "Single Monitor Arm",
        "cover": "https://portal99.ais-inc.com/assets/images/a-ersmasb.jpg",
        "active": false,
        "instruction": false
    }
]

const treeitems = [
    {
        "model": {
            "id": "P43325",
            "catalog": "SCR",
            "productId": 43325,
            "domainId": 0,
            "optionId": 0,
            "item": "T-RCR246029HG",
            "itemDesc": "24\" X 60\" Height Adjustable Table",
            "bomType": 0,
            "hasChild": true,
            "parent_id": "0",
            "level": 0,
            "lblnewhidden": "hidden",
            "lblexistshidden": "hidden",
            "parentChanged": false,
            "price": 2325.0,
            "grade": null
        },
        "name": "24\" X 60\" Height Adjustable Table",
        "cover": "assets/images/t-rcr246029hg.jpg",
        "active": false,
        "isConfigurated": false
    },
    {
        "model": {
            "id": "135203398",
            "catalog": "",
            "productId": 43325,
            "domainId": 135203398,
            "optionId": 0,
            "item": "WFH-LAM",
            "itemDesc": "LAMINATE CHOICE",
            "bomType": 1,
            "hasChild": true,
            "parent_id": "P43325",
            "level": 1,
            "lblnewhidden": "hidden",
            "lblexistshidden": "hidden",
            "parentChanged": false,
            "price": 0.0,
            "grade": ""
        },
        "name": "LAMINATE CHOICE",
        "cover": "assets/images/wfh-lam.jpg",
        "active": false,
        "isConfigurated": false
    },
    {
        "model": {
            "id": "O135204753",
            "catalog": "",
            "productId": 43325,
            "domainId": 135203398,
            "optionId": 135204753,
            "item": "RO-L0095",
            "itemDesc": "Corporate Walnut",
            "bomType": 0,
            "hasChild": false,
            "parent_id": "135203398",
            "level": 2,
            "lblnewhidden": "hidden",
            "lblexistshidden": "hidden",
            "parentChanged": false,
            "price": 0.0,
            "grade": ""
        },
        "name": "Corporate Walnut",
        "cover": "assets/images/ro-l0095.jpg",
        "active": false,
        "isConfigurated": false
    },
    {
        "model": {
            "id": "O135204754",
            "catalog": "",
            "productId": 43325,
            "domainId": 135203398,
            "optionId": 135204754,
            "item": "RO-L0222",
            "itemDesc": "Light Gray",
            "bomType": 0,
            "hasChild": false,
            "parent_id": "135203398",
            "level": 2,
            "lblnewhidden": "hidden",
            "lblexistshidden": "hidden",
            "parentChanged": false,
            "price": 0.0,
            "grade": ""
        },
        "name": "Light Gray",
        "cover": "assets/images/ro-l0222.jpg",
        "active": false,
        "isConfigurated": false
    }
]

async function GetCategories(): Promise<Category[]>{

    await delay(300);

    return categories;
}

async function GetProductsByCategory(category: Category): Promise<Product[]>{

    await delay(300);

    return [...products];
}

async function GetProductByID(id: number): Promise<Product> {
    await delay(300);

    return {...products.filter(x => x.model.id == id)[0]};
}

async function GetProductOption(id: number): Promise<TreeItem[]> {
    await delay(500);
    
    return (id === 43328) ? [...treeitems]: [];
}

async function GetHomeData(): Promise<HomeCategory[]>{
    await delay(300);
    
    return homedata;
}

export const Service = {
    homedata,
    categories,
    products,
    GetCategories,
    GetProductsByCategory,
    GetProductByID,
    GetProductOption,
    GetHomeData
};
