import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import PriceList from '@/views/PriceList.vue';
import PriceListSummary from '@/views/PriceListSummary.vue';
import Login from '@/views/Login.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Home
  // },
  // {
  //   path: '/collections',
  //   name: 'collections',
  //   component: Collections,
  //   children: [
  //     {
  //       path: '',
  //       name: 'list-collections',
  //       component: ListCollections,
  //       meta: { requiresAuth: false, parent: 1 }
  //     },
  //     {
  //       path: ':slug',
  //       name: 'category',
  //       component: Category,
  //       meta: { requiresAuth: false, parent: 1 }

  //     },
  //     {
  //       path: ':slug/product/:product',
  //       name: 'product',
  //       component: Product,
  //       meta: { requiresAuth: false, parent: 1 }
  //     },
  //   ]
  // },
  // {
  //   path: '/checkout',
  //   name: 'checkout',
  //   component: Checkout,
  // },
  {
    path: '/price-list',
    name: 'price-list',
    component: PriceList,
    meta: { requiresAuth: true }
  },
  {
    path: '/list-summary',
    name: 'list-summary',
    component: PriceListSummary,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '*',
    redirect: '/price-list'
  }
]

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const loggedIn = localStorage.getItem('user');
    if (!loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
});

export default router
