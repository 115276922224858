import { CartLine, CheckoutFormData } from '@/helpers/types';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';

@Module({ namespaced: true })
class CartModule extends VuexModule{
    public items: CartLine[] = [];
    public isLoading = false;

    @Mutation
    public AddItem(item: CartLine): void {
        this.items?.push(item);
    }

    @Mutation
    public RemoveItem(index: number): void {
        if (index >= 0 && index < this.items.length){
            this.items.splice(index, 1);
        }
    }

    @Action
    public OnAddItem(item: CartLine): void {
        this.context.commit('AddItem', item);
        this.context.dispatch('configurator/ConfiguratorReset', null, { root: true })
    }

    @Action
    public OnRemoveItem(index: number, item: CartLine): void {
        this.context.commit('RemoveItem', index);
    }

    @Action
    public CheckoutItems(formData: CheckoutFormData): void {
        console.log(formData);
    }

}

export default CartModule;