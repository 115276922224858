const screen = [
        {
            title: 'Channel Screen',
            description: 'The Channel style screen has a painted aluminum channel which holds a frameless piece of 1/4 thick or 3/8ths thick Glass or Lexan or a 1/2 inch thick piece of PET.  The materials are held by set screws on one side which are then covered by a cover extrusion and endcaps.  The channel screen can be mounted directly to a worksurface, panel, storage element or gallery panel or  attached to a number of different brackets or feet depending on Mount Type chosen.',
            imgs: ['/assets/gallery/channel_screen1.jpg', 
                    '/assets/gallery/channel_screen2.jpg',
                    '/assets/gallery/channel_screen3.jpg',
                    '/assets/gallery/channel_screen4.jpg',
                    '/assets/gallery/channel_screen5.jpg',
                    '/assets/gallery/channel_screen6.jpg']
        },
        {
            title: 'Clip Screen',
            description: 'The Clip style screen is a frameless piece of 1/4 thick or 3/8ths thick Glass or Lexan held by a matte silver aluminum diecast clamp assembly which is attached either directly to a worksurface, panel, storage element or gallery panel or attached to a number of different brackets or feet depending on Mount Type chosen.',
            imgs: ['/assets/gallery/clip_screen1.jpg', 
                    '/assets/gallery/clip_screen2.jpg',
                    '/assets/gallery/clip_screen3.jpg',
                    '/assets/gallery/clip_screen4.jpg',
                    '/assets/gallery/clip_screen5.jpg',
                    '/assets/gallery/clip_screen6.jpg']
        },        
        {
            title: 'Lite Clip Screen',
            description: 'The Lite Clip PET screen has painted steel "U" shaped brackets of various sorts that hold 1/2 inch thick PET for a variety of Mount Types, as well as "L" shaped brackets which attach on one side with screws for Partial Modesty applications.  Lite Clip PET Screens can be ordered as L-wraps or U-wraps with bendable sections of the screen placed at surface corners.',
            imgs: ['/assets/gallery/lite_screen1.jpg', 
                    '/assets/gallery/lite_screen2.jpg',
                    '/assets/gallery/lite_screen3.jpg',
                    '/assets/gallery/lite_screen4.jpg',
                    '/assets/gallery/lite_screen5.jpg',
                    '/assets/gallery/lite_screen6.jpg',
                    '/assets/gallery/lite_screen7.jpg',
                    '/assets/gallery/lite_screen8.jpg']
        },
        {
            title: 'Slimline Framed Screen',
            description: 'The slimline framed screen has a painted aluminum frame which holds either fabric, vinyl, whiteboard or laminate inserts on the front and back.  The bottom corners of the frame have re-inforced threaded holes which allow attachment of various brackets and feet depending on the mount type chosen.  The fabric version of this screen is tackable, while the whiteboard version can be used with dry erase markers.  This screen can be specified with different materials front and back.',
            imgs: ['/assets/gallery/slimline_screen1.jpg', 
                    '/assets/gallery/slimline_screen2.jpg',
                    '/assets/gallery/slimline_screen3.jpg',
                    '/assets/gallery/slimline_screen4.jpg',
                    '/assets/gallery/slimline_screen5.jpg',
                    '/assets/gallery/slimline_screen6.jpg',
                    '/assets/gallery/slimline_screen7.jpg']
        },
        {
            title: 'Universal Spine Insert Screen',
            description: 'Oxygen, Aloft and Day to Day Powerbeam all share the same FIXED HEIGHT top spine element called the Universal Spine.  The Universal Spine can hold frameless "Inserts" of Glass, Lexan or PET without any other mounting hardware or channels required.   These insert screens are only for use on Oxygen, Aloft or Powerbeam Spines.',
            imgs: ['/assets/gallery/universal_screen1.jpg', 
                    '/assets/gallery/universal_screen2.jpg',
                    '/assets/gallery/universal_screen3.jpg',
                    '/assets/gallery/universal_screen4.jpg',
                    '/assets/gallery/universal_screen5.jpg',
                    '/assets/gallery/universal_screen6.jpg']
        },
];

const material = [
    {
        title: 'Materials',
        description: 'Tempered glass screens have 90 degree polished corners.  Lexan screens and PET screens have 1.5 inch radius corners.  Slimline screens have multiple paint choices for frame and surface and are available with different options for front and back.  Fabric screens are tackable.',
        imgs: ['/assets/gallery/materials1.jpg',]
    }
]

const mount = [    
    {
        title: 'Freestanding',
        description: 'Freestanding screens have 12 inch deep (6 on each side) low profile painted steel feet with rubber pads.',
        imgs: ['/assets/gallery/freestanding1.png', 
                '/assets/gallery/freestanding2.png',
                '/assets/gallery/freestanding3.png',
                '/assets/gallery/freestanding4.png',]
    },
    {
        title: 'Surface Mount',
        description: 'Surface mount screens screw directly to a worksurface, panel or storage element with hardware provided and will leave holes in worksurface, panel or storage element when screen is removed.',
        imgs: ['/assets/gallery/surface1.png', 
                '/assets/gallery/surface2.png',
                '/assets/gallery/surface3.png',
                '/assets/gallery/surface4.png',
                '/assets/gallery/surface5.png',
                '/assets/gallery/surface6.png',
                '/assets/gallery/surface7.png']
    },
    {
        title: 'Under Surface Mount',
        description: 'Screens with this mounting type are held by brackets that screw to the underside of a worksurface to prevent marring the top of a surface.  As there are often different legs or supports used for worksurfaces, it is important to specify the correct "Where Used" and Sized For" fields.',
        imgs: ['/assets/gallery/undersurface1.png', 
                '/assets/gallery/undersurface2.png',
                '/assets/gallery/undersurface3.png',
                '/assets/gallery/undersurface4.png',
                '/assets/gallery/undersurface5.png']
    },
    {
        title: 'Universal Spine Mount',
        description: 'This mount type applies to SLIMLINE SCREENS ONLY, when mounting on top of the Universal Spine used on Aloft, Oxygen and Powerbeam.',
        imgs: ['/assets/gallery/universal_spine1.png', 
                '/assets/gallery/universal_spine2.png',
                '/assets/gallery/universal_spine3.png',
                '/assets/gallery/universal_spine4.png',]
    },
]

const whereused = [
    {
        title: 'Front/Rear on Worksurface',
        description: 'The <strong>Front/Rear</strong> choice is for screens mounted on what is generally the long sides of a table, either the front or the back, and is correlated to the WIDTH.  Front/Rear screens can also be freestanding and thus sometimes are placed in the center dividing the long axis of a table.',
        imgs: ['/assets/gallery/whereused2.png',]
    },
    {
        title: 'Gallery Panel Side Mount',
        description: 'Some Lexan and PET Channel Screens can be used mounted to the side of a gallery panel, corresponding to it\'s height.',
        imgs: ['/assets/gallery/whereused3.png',]
    },
    {
        title: 'L-Wrap on Worksurface',
        description: 'L-Wrap on Worksurface corresponds to Surface Mounted or Undersurface Mounted PET screens that have a folding section on one side to form a 90 degree angle.  These screens have a width component and a depth component.',
        imgs: ['/assets/gallery/whereused4.png',]
    },
    {
        title: 'L-Wrap Partial Modesty on Worksurface',
        description: 'L-Wrap Partial Modesty on Worksurface corresponds to PET screens that have a folding section on one side to form a 90 degree angle that also hang below the bottom of the surface form a modesty. These screens have a width component and a depth component.',
        imgs: ['/assets/gallery/whereused5.png',]
    },
    {
        title: 'Panel Top',
        description: 'Panel top refers to screens that mount on the tops of various panel systems.  Usually these screens have specific hardware or dimensions for matching certain panel systems.',
        imgs: ['/assets/gallery/whereused6.png',]
    },
    {
        title: 'Partial Modesty on Worksurface',
        description: 'Partial Modesty on Worksurface corresponds to a rectangular PET or Slimline Screen that mounts such that the screen also hangs below the bottom of the surface form a modesty.  These screens mount to one side of a surface.  ',
        imgs: ['/assets/gallery/whereused7.png',]
    },
    {
        title: 'Territory Drop on Worksurface',
        description: 'Territory Drop on Worksurface refers to a Territory screen with a section of the screen that extends 12 inches from front worksurface edge and "drops" down 8 inches between or adjacent to a user. ',
        imgs: ['/assets/gallery/whereused8.png',]
    },
    {
        title: 'Territory on Worksurface',
        description: 'The "Territory" choice refers to screens that are used "between" users generally, at their left or right, or shared between them in certain cases, dividing their "territory".  Often Territory Screens correlate the a table\'s DEPTH.',
        imgs: ['/assets/gallery/whereused9.png',]
    },
    {
        title: 'Universal Spine',
        description: 'The Universal Spine Choice refers to a screen designed to be mounted to the Aloft, Oxygen or Day to Day Powerbeam Spine, now known as the Universal Spine.',
        imgs: ['/assets/gallery/whereused10.png',]
    },
    {
        title: 'U-Wrap on Worksurface',
        description: 'U-Wrap on Worksurface corresponds to Surface Mounted or Undersurface Mounted PET screens that have a folding section on two sides to form a 90 degree angle on each end.  These screens have a width component and a depth component.',
        imgs: ['/assets/gallery/whereused11.png',]
    },
    {
        title: 'U-Wrap Partial Modesty on Worksurface',
        description: 'U-Wrap Partial Modesty on Worksurface corresponds to PET screens that have a folding section on two sides to form a 90 degree angle on both ends that also hang below the bottom of the surface form a modesty.  These screens have a width component and a depth component.',
        imgs: ['/assets/gallery/whereused12.png',]
    }
]

const sizedfor = [
    {
        title: "Sized For",
        description: '"Sized For" refers to the specific dimension scenario a screen is being used for.  For instance, Height Adjustable Tables have smaller surfaces than fixed height tables for pinch point clearance.  A screen mounting on the left or right or rear of a Height Adjustable Table is smaller in actual width than a Fixed Height Table because the HAT table is smaller.  The ACTUAL DIMENSION (What you would measure it) of a Front/Rear screen for a 60w NOMINAL (The generic rounded number dimension in the description) HAT table is 58 inches wide.  The Actual Dimension for a screen for a Fixed Height Table would be 2 inches longer.  There could be many actual dimensions for a nominal dimension.  Rather than trying to remember all the specific dimensions, just choose the correct "Sized For" parameter from the list.</br> Sometimes, the actual dimension required MATCHES the nominal dimension required.  When you want a screen that matches the nominal dimension, CHOOSE "Actual Size = Nominal Size".   This can be helpful when the screen you want is not mounting on an AIS product or perhaps it is a product that doesn\'t require a specific size.  One example is a freestanding screen.',
        imgs: []
    },
    {
        title: 'All Gallery Panel Except Divi',
        description: '',
        imgs: ['/assets/gallery/sizedfor1.png']
    },
    {
        title: 'Divi Gallery Panels',
        description: '',
        imgs: ['/assets/gallery/sizedfor2.png']
    },
    // {
    //     title: 'Aloft and Benching Hat Depth',
    //     description: '',
    //     imgs: ['/assets/gallery/sizedfor3.png']
    // },
    {
        title: 'Aloft Worksurface Width',
        description: '',
        imgs: ['/assets/gallery/sizedfor4.png']
    },
    {
        title: 'Aloft and Benching HAT Depth',
        description: '',
        imgs: ['/assets/gallery/sizedfor5.png']
    },
    {
        title: 'Day to Day, Calibrate and Benching HAT Width',
        description: '',
        imgs: ['/assets/gallery/sizedfor6.png']
    },
    {
        title: 'Day to Day, Calibrate and Benching HAT Width',
        description: '',
        imgs: ['/assets/gallery/sizedfor7.png']
    },
    {
        title: 'Divi Panels Dimensions',
        description: '',
        imgs: ['/assets/gallery/sizedfor8.png']
    },
    {
        title: 'L-Wrap for Day to Day or Calibrate HAT',
        description: '',
        imgs: ['/assets/gallery/sizedfor9.png']
    },
    {
        title: 'L-Wrap for Nominal Size = Actual Size',
        description: '',
        imgs: ['/assets/gallery/sizedfor10.png']
    },
    {
        title: 'Nominal Size = Actual Size',
        description: '',
        imgs: ['/assets/gallery/sizedfor11.png']
    },
    {
        title: 'Oxygen Worksurface Depth',
        description: '',
        imgs: ['/assets/gallery/sizedfor12.png']
    },
    {
        title: 'U-Wrap for Banching HAT',
        description: '',
        imgs: ['/assets/gallery/sizedfor13.png']
    },
    {
        title: 'U-Wrap for Day to Day and Calibrate HAT',
        description: '',
        imgs: ['/assets/gallery/sizedfor14.png']
    },
    {
        title: 'U-Wrap for Nominal Size = Actual Size',
        description: '',
        imgs: ['/assets/gallery/sizedfor14.png']
    }   
];

const handedness = [
    {
        title: 'Left and Right Handed',
        description: '',
        imgs: ['/assets/gallery/handedness1.png']
    },
    {
        title: 'Shared',
        description: '',
        imgs: ['/assets/gallery/handedness2.png']
    } 
]

const withourwithoutcutout = [
    {
        title: 'With Cutout',
        description: '',
        imgs: ['/assets/gallery/withorwithoutcutout1.png', '/assets/gallery/withorwithoutcutout3.png']
    },
    {
        title: 'Without Cutout',
        description: '',
        imgs: ['/assets/gallery/withorwithoutcutout2.png', '/assets/gallery/withorwithoutcutout4.png']
    }
]



export const DescriptionService = {
    screen,
    material,
    mount,
    whereused,
    sizedfor,
    handedness,
    withourwithoutcutout
}