






















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Service } from "@/services";
import { PopUpProps } from '@/helpers/types';
const popup = namespace("popup");

@Component
export default class PopUpComponent extends Vue {
  @popup.State
  public show!: boolean;
  @popup.State
  public dataPopup!: PopUpProps;

  public onYes(): void {
    if (this.dataPopup.onYes) this.dataPopup.onYes()
  }

  public onNo(): void {
     if (this.dataPopup.onNo) this.dataPopup.onNo()
  }
}
